var slickGallery;

$(function () {
    initTabs();
    initExpand();
    initSlick();
    initNav();
    initJumpLinks();
    initPopup();
    showcaseFrameAutoscroll();
    initMasonryGrid();
    initBlogFilter();
});

$(window).on('load', function () {
});

$(window).scroll(function () {
    borderedBlocksScrollAnimation();
});

function borderedBlocksScrollAnimation(){
    $animateBlocks = $('.js-scroll-trigger');
    if($animateBlocks.length > 0){
        var scroll = $(window).scrollTop();
        $animateBlocks.each(function(){
            var pos = $(this).offset().top;
            var screenHeight = $(window).outerHeight();
            // Start the animation when the element is 7/8s of the way down the screen
            if(scroll > pos - screenHeight * 3 / 4){
                add_animate_classes($(this));
            }
        })
    }
}

function add_animate_classes($this){
    $this.addClass('animate');
}

/**
 * Inits the nav setup
 */
function initNav() {
    // open/close site navigation
    $('.js-nav-toggle').off().on('click', function () {
        if(!$(this).hasClass('burger--open')){
            $(this).toggleClass('burger--open');
            $('#nav-main').toggleClass('open');
            if($(window).width() < 840){
                $('.nav__panel').animate({width: '100vw'}, 600, 'swing');
            }
            else{
                $('.nav__panel').animate({width: '495px'}, 600, 'swing');
            }
            $('.nav__background').animate({opacity: 0.5}, 600, 'swing');
            $('html, body').toggleClass('site-nav-showing');
        }
        return false;
    });
    $('body, .js-nav-close').off().on('click', function(){
        $('.js-nav-toggle').removeClass('burger--open');
        $('.nav__background').animate({opacity: 0}, 600, 'swing');
        $('.nav__panel').animate({width: '0px'}, 600, 'swing', function() {
            $('#nav-main').removeClass('open');
        });
        $('html, body').removeClass('site-nav-showing');
    });
    $('.js-nav-panel').off().on('click', function(e){
        e.stopPropagation();
    });
    $('.js-subnav').off().on('click', function () {
        $(this).find('.open-link-arrow-outer').toggleClass('open');
        $(this).parents('li').find('ul').slideToggle();
        return false;
    });
}

/**
 * Scrolls smoothly to the passed element
 * @param {string} element The element to scroll to
 */
function scrollToElement(element) {
    var offset = $(element).offset().top - 60;
    $('html, body').animate({
        scrollTop: offset
    }, 500);
}

/**
 * Old adaptive images function, not needed if using imagesSizes
 */
// function init_adp_images() {
// 	$('.adp-image').each(function () {
// 		var image;
// 		if (typeof $(this).attr('data-src-' + get_device()) === 'undefined') {
// 			image = $(this).data("src-desktop");
// 		} else {
// 			image = $(this).data("src-" + get_device());
// 		}

// 		if ($(this).attr('data-bg') === 'true') {
// 			$(this).css("background-image", 'url(' + image + ')');
// 		} else {
// 			$(this).attr("src", image);
// 		}
// 	});
// }

/**
 * Toggle between tabbed content when tabs are clicked
 */
function initTabs() {
    $('.js-tab-link').off().on('click', function () {
        if (!$(this).hasClass('active')) {
            var thisSlug = $(this).attr('data-slug');
            var tabsId = $(this).attr('data-tabs-id');
            var theseTabs = $('.tabs[data-tabs-id="' + tabsId + '"]');
            $(this).addClass('active').siblings().removeClass('active');
            height = theseTabs.find('.js-tab-content.open').outerHeight();
            theseTabs.css('min-height', height);
            var fadeTime = 400;
            if(theseTabs.hasClass('no-fade')){
                fadeTime = 0;
            }
            // If one is already open, make sure to fade it out first.
            if(theseTabs.find('.js-tab-content.open').length > 0){
                theseTabs.find('.js-tab-content.open').fadeOut(fadeTime, function(){
                    theseTabs.find('.js-tab-content').removeClass('open');
                    $thisTab = theseTabs.find('.js-tab-content[data-slug="' + thisSlug + '"]');
                    $thisTab.fadeIn(fadeTime).addClass('open');
                    theseTabs.css('min-height', '');
                    $slider = $thisTab.find('.js-slick');
                    $slider.slick('unslick').addClass('slick-loading');
                    initSlickSpecific($slider);
                });
            }
            // Else just fade it in
            else{
                $thisTab = theseTabs.find('.js-tab-content[data-slug="' + thisSlug + '"]');
                $thisTab.fadeIn(fadeTime).addClass('open');
                theseTabs.css('min-height', 'none');
                $slider = $thisTab.find('.js-slick');
                $slider.slick('unslick').addClass('slick-loading');
                initSlickSpecific($slider);
            }
        };
        if($(window).width() < 900){
            console.log('scrollto');
            var thisSlug = $(this).attr('data-slug');
            var tabsId = $(this).attr('data-tabs-id');
            var theseTabs = $('.tabs[data-tabs-id="' + tabsId + '"]');
            $thisTab = theseTabs.find('.js-tab-content[data-slug="' + thisSlug + '"]');
            $('html, body').stop().animate({
                'scrollTop': $thisTab.offset().top - 32
            }, 400, 'swing')}
        return false;
    });
    $('.js-tab-link a.follow-link').off().on('click', function(e){
        e.stopPropagation();
    });
}

/**
 * Show/hide the area of content directly after the expand link
 */
function initExpand() {
    $('.js-expand').off().on('click', function () {
        if ($(this).hasClass('js-close-others')) {
            $(this).parent().find('.js-expand.active').removeClass('active');
            $(this).parent().find('.tab-content.open').removeClass('open');
        }
        $(this).toggleClass('active').next().toggleClass('open');
        return false;
    });
}

/**
 * Initialise slick carousels
 */
function initSlick() {
    setTimeout(function () { // give images a chance to load before starting
        slickGallery = $('.js-slick');
        if (slickGallery.length > 0) {
            slickGallery.slick({
                fade: true,
                prevArrow: '<a href="#" class="slick-prev"><span class="visuallyhidden">Previous</span></a>',
                nextArrow: '<a href="#" class="slick-next"><span class="visuallyhidden">Next</span></a>',
                dots: true
            });
        }
    }, 200);
    setTimeout(function () { // give images a chance to load before starting
        slickGallery = $('.js-case-study-slick');
        if (slickGallery.length > 0) {
            slickGallery.slick({
                fade: true,
                prevArrow: '<a href="#" class="slick-arrow slick-prev"><span class=""><i class="fa-solid fa-chevron-left"></i></span></a>',
                nextArrow: '<a href="#" class="slick-arrow slick-next"><span class=""><i class="fa-solid fa-chevron-right"></i></span></a>',
                dots: true
            });
        }
    }, 200);
}

function initSlickSpecific($slider) {
    // setTimeout(function () { // give images a chance to load before starting
        if ($slider.length > 0) {
            $slider.slick({
                fade: true,
                prevArrow: '<a href="#" class="slick-prev"><span class="visuallyhidden">Previous</span></a>',
                nextArrow: '<a href="#" class="slick-next"><span class="visuallyhidden">Next</span></a>',
                dots: true
            });
        }
    // }, 200);
}

/**
 * Initialises jump links to smooth scroll down the page
 */
function initJumpLinks() {
    $('.js-jump-link').off().on('click', function () {
        var hash = $(this).attr('href').split('#');
        if ($('#' + hash[1]).length > 0) {
            scrollToElement('#' + hash[1], ($('.site-header').height() - 150));
            return false;
        }
    });
}

function initPopup() {
    $('.popup').on('click', '.close-button', function (event) {
        event.preventDefault();
        /* Act on the event */
        $('.popup-overlay').hide();
    });
    $('.popup').click(function (e) {
        e.stopPropagation();
    });
    $('body').on('click', function (event) {
        /* Act on the event */
        $('.popup-overlay').hide();
    });
    $('.js-open-popup').on('click', function(e){
        $('.popup-overlay').removeClass('hidden').show();
        e.stopPropagation();
    });
}

function showcaseFrameAutoscroll(){
    $scroll = $('.js-desktop-scroll').first();
    if($scroll.length > 0){
        var windowPos = $(window).scrollTop();
        var pos = $scroll.offset().top;
        // console.log('pos: ' + pos);
        var screenHeight = $(window).outerHeight();
        // console.log('height: ' + screenHeight);
        // console.log('comp: ' + (pos - (screenHeight / 2)));
        // Start the animation when the element is 7/8s of the way down the screen
        if(windowPos > pos - screenHeight * 3 / 4){
            add_scroll_animate_classes();
        }
    }
}

function add_scroll_animate_classes($scroll){
    setTimeout(function(){
        $('.js-autoscroll-set').each(function(){
            setup_scrolling($(this));
        })
        // Wait a second for images to load
    }, 1000);
}

function setup_scrolling($this){
    scroll_desktop = false;
    scroll_mobile = false;
    $desktop = $this.find('.js-desktop-scroll');
    // console.log($desktop.length);
    $img = $desktop.find('img');
    holder_height = $desktop.outerHeight();
    img_height = $img.outerHeight();
    // console.log(img_height);
    if(img_height > holder_height *  1.4){
        scroll_desktop = true;
    }
    $mob = $this.find('.js-mobile-scroll');
    $mob_img = $mob.find('img');
    // console.log('mob img: ' + $mob_img.length)
    mob_holder_height = $mob.outerHeight();
    mob_img_height = $mob_img.outerHeight();
    if(mob_img_height > mob_holder_height *  1.4){
        scroll_mobile = true;
    }
    if(scroll_desktop && scroll_mobile){
        // desktop calcs
        ratio = img_height / holder_height;
        percent = ratio * 100;
        scrollable_percent = -(percent - 100) + '%';
        // mob calcs
        mob_ratio = mob_img_height / mob_holder_height;
        mob_percent = mob_ratio * 100;
        mob_scrollable_percent = -(mob_percent - 100) + '%';
        // desktop scroll
        time = 2500;
        mob_time = 5000;
        $img.animate({
            top: scrollable_percent
        }, time).animate({
            top: '0%'
        }, time, function(){
            mob_callback($(this));
        });
        // autoscroll_animate($img, holder_height, img_height).then(function(){
        //     autoscroll_animate_mob($mob_img, mob_holder_height, mob_img_height);
        // });
    }
}

function mob_callback($this){
    $mob = $this.parents('.js-autoscroll-set').find('.js-mobile-scroll');
    $mob_img = $mob.find('img');
    mob_holder_height = $mob.outerHeight();
    mob_img_height = $mob_img.outerHeight();

    mob_ratio = mob_img_height / mob_holder_height;
    mob_percent = mob_ratio * 100;
    mob_scrollable_percent = -(mob_percent - 100) + '%';

    $mob_img.animate({
        top: mob_scrollable_percent
    }, mob_time).animate({
        top: '0%'
    }, mob_time, function(){
        desktop_callback($(this));
    })
}

function desktop_callback($this){
    $desktop = $this.parents('.js-autoscroll-set').find('.js-desktop-scroll');
    $img = $desktop.find('img');
    holder_height = $desktop.outerHeight();
    img_height = $img.outerHeight();

    ratio = img_height / holder_height;
    percent = ratio * 100;
    scrollable_percent = -(percent - 100) + '%';

    $img.animate({
        top: scrollable_percent
    }, time).animate({
        top: '0%'
    }, time, function(){
        mob_callback($(this));
    })
}

// Sizing of masonry grid is all done with css, this is just for the overlays
function initMasonryGrid(){
    $('.overlay').on('click', function(e){
        $(this).addClass('clicked');
        $hint = $(this).find('.hint');
        $info = $(this).find('.info');
        $hint.css('opacity', 0);
        $info.css('visibility', 'visible');
        width = $info.outerWidth();
        $info.animate({
            'opacity': 1
        });
        $(this).animate({
            'min-width': width + 32 + "px"
        })
        e.stopPropagation();
        e.preventDefault();
    });
}

function initBlogFilter(){
    $('.js-filter').on('click', function(){
        if(!$(this).hasClass('active')){
            $('.js-filter').removeClass('active');
            $(this).addClass('active');
            $('.module--blog-filter-grid .blog-grid').addClass('loading');
            cat = $(this).attr('data-slug');
            $.ajax({
                type: "GET",
                url: "/wp-json/blog-posts/filter?category=" + cat,
                dataType: "html"
            }).done(function (result) {
                $('.module--blog-filter-grid .blog-grid').html(result);
                $('.module--blog-filter-grid .blog-grid').removeClass('loading');
            });
        }
    });
}