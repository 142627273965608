/* exported googleMaps */

/**
 * Creates a Google map
 */
var googleMaps = function() {
    'use strict';
    var options = {
        mapId: 'google-map',
        defaultZoomLevel: 16,
        defaultCenterPos: {
            lat: 51.4312446,
            lng: -2.1874367
        }
    };

    /**
     * Create map markers
     */
    var createMarker = function () {
        var markerData = { // TEMP (would usually be pulled from somewhere)
            name: 'Test',
            description: 'Test description text',
            url: 'http://www.google.com'
        };
        var marker = new google.maps.Marker({
            map: options.map,
            title: markerData.name,
            position: new google.maps.LatLng(51.4312446, -2.1874367)
        });
        // createInfoWindow(marker, markerData);
    };

    /**
     * Create an info window on the passed marker
     * @param {object} marker - Google map marker
     * @param {object} markerData - Marker information
     */
    var createInfoWindow = function (marker, markerData) {
        google.maps.event.addListener(marker, 'click', function () {
            var infoWindow = new google.maps.InfoWindow();
            infoWindow.setContent(`
                <div class="map-info-window">
                    ${markerData.name ? `<h4>${markerData.name}</h4>` : ''}
                    ${markerData.description ? `<p>${markerData.description}</p>` : ''}
                    ${markerData.url ? `<a href="${markerData.url}" class="button">View</a>` : ''}
                </div>
            `);
            infoWindow.open(options.map, marker);
        });
    };

    return {
        /**
         * Initialises the map and sets the options
         */
        init: function() {
            if ($('#' + options.mapId).length > 0) {
                options.map = new google.maps.Map(document.getElementById(options.mapId), {
                    center: options.defaultCenterPos,
                    zoom: options.defaultZoomLevel,
                    streetViewControl: false,
                    mapTypeControl: false,
                    fullscreenControl: false,
                    styles: []
                });
                createMarker();
            }
        }
    };
}();